function app() {
  var __DOMReady = function (a, b, c) {
    (b = document), (c = "addEventListener");
    b[c] ? b[c]("DOMContentLoaded", a) : window.attachEvent("onload", a);
  };

  __DOMReady(function () {
    bindEvents();
  });

  function bindEvents() {
    document.addEventListener("click", function (e) {
      var qs = document.querySelectorAll(".maitres-widget");
      if (qs) {
        var el = e.target,
          index = -1;
        while (el && (index = Array.prototype.indexOf.call(qs, el)) === -1) {
          el = el.parentElement;
        }
        if (index > -1) {
          e.preventDefault();
          e.stopPropagation();
          loadIframeWidget(el);
        }
      }
    });
  }

  function loadIframeWidget(el) {
    var hash = el.getAttribute("data-hash") || "test";
    var params = {
      unitId: "",
      system: "",
      theme: "",
      lang: "",
      step_card: false
    };
    if (hash === "") {
      console.error("The attribute data-hash can't be empty ");
      return false;
    }
    const url = `//${process.env.BACKEND_HOST}?hash=${hash}`;
    const request = new XMLHttpRequest();
    request.open("GET", url);

    request.send();

    request.onload = function () {
      if (request.status != 200) {
        console.log(`Error ${request.status}: ${request.statusText}`);
      } else {
        var body = document.querySelector("body");
        var html = document.documentElement;
        const response = JSON.parse(request.response);

        params.unitId = response.unitId ? response.unitId : params.unitId;
        params.system = response.system ? response.system : params.system;

        console.log("Binding for unitId: " + params.unitId);
        var iframe_url = location.protocol + `//${process.env.IFRAME_HOST}?`;
        iframe_url += "unitId=" + params.unitId;
        iframe_url += "&system=" + params.system;
        html.classList.remove("with-popup");
        var wa_popupElement = document.createElement("div");
        var wa_modal = document.createElement("div");
        wa_popupElement.classList.add("bb_modaloverlay");
        wa_popupElement.setAttribute(
          "style",
          "position: fixed;top: 0;right: 0;bottom: 0;left: 0;background: rgba(0,0,0,.7);z-index: 99999;display: flex;align-items: center;padding: 15px;justify-content: center;"
        );
        var css = `
        @media(max-width: 767px) {
          .bb_modaloverlay {
            padding: 0 !important;
          }
          .bb_modal {
            height: 100% !important;
            max-height: none !important;
          }
        }
        `,
          head = document.head || document.getElementsByTagName("head")[0],
          style = document.createElement("style");

        head.appendChild(style);

        if (style.styleSheet) {
          // This is required for IE8 and below.
          style.styleSheet.cssText = css;
        } else {
          style.appendChild(document.createTextNode(css));
        }
        wa_modal.setAttribute(
          "style",
          "width: 100%;height: 100%;display: flex;height: calc(100vh - 150px);max-height: 720px;"
        );
        wa_modal.classList.add("bb_modal");
        wa_popupElement.appendChild(wa_modal);
        var iframe = document.createElement("iframe");

        iframe.setAttribute("src", iframe_url);
        iframe.setAttribute("name", request.response);
        iframe.setAttribute("frameborder", "0");
        iframe.setAttribute("scrolling", "auto");
        iframe.setAttribute("width", "100%");
        iframe.setAttribute("height", "100%");
        iframe.setAttribute("style", "max-width: 600px;margin: auto;");
        document
          .querySelector("body")
          .setAttribute("style", "overflow: hidden; height: 100%");
        wa_modal.appendChild(iframe);
        body.appendChild(wa_popupElement);
        html.classList.add("with-popup");

        var specifiedElement = document.querySelector(".bb_modaloverlay");
        var modal = document.querySelector(".bb_modaloverlay iframe");

        window.addEventListener("message", (event) => {
          if (event.data.message === "close") {
            specifiedElement.remove();
            document
              .querySelector("body")
              .setAttribute("style", "overflow: auto;");
          }
        });

        specifiedElement.addEventListener("click", function (event) {
          var isClickInside = modal.contains(event.target);

          if (!isClickInside) {
            specifiedElement.remove();
            document
              .querySelector("body")
              .setAttribute("style", "overflow: auto;");
          }
        });
      }
    };

    request.onerror = function () {
      alert("Ошибка " + this.status);
    };
  }
}

app();
